<template>
    <div>
        <van-nav-bar title="温馨提示" left-arrow @click-left="onClickLeft" />
        <van-image width="100%"  src="/wenxintishi/wenxintishi1.jpg" />
        <van-image width="100%"  src="/wenxintishi/wenxintishi2.jpg" />
        <van-image width="100%"  src="/wenxintishi/wenxintishi3.jpg" />
    </div>
</template>

<script>

export default {
    methods: {
        onClickLeft() {
            this.$router.push('/')
        }
    }
};
</script>

<style scoped></style>
